var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "animated fadeIn"
  }, [_c('b-card', [_vm.reportingAlertText ? _c('b-alert', {
    attrs: {
      "show": "",
      "variant": "danger"
    }
  }, [_vm._v(_vm._s(_vm.reportingAlertText) + " ")]) : _vm._e(), _c('b-tabs', {
    attrs: {
      "content-class": "mt-3",
      "justified": ""
    }
  }, [_c('b-tab', {
    attrs: {
      "title": "Overview",
      "active": ""
    }
  }, [_c('form', [_c('h5', [_vm._v(" Distillation details "), _vm.isLoading ? _c('b-spinner', {
    staticClass: "mb-1",
    attrs: {
      "small": "",
      "type": "grow"
    }
  }) : _vm._e()], 1), _c('hr'), _c('b-row', [_c('b-col', {
    attrs: {
      "lg": "3",
      "md": "6",
      "sm": "6"
    }
  }, [_c('inline-input', {
    attrs: {
      "id": _vm.controls.id.id,
      "value": _vm.data.id,
      "label": _vm.controls.id.label,
      "readonly": _vm.controls.id.readonly,
      "mode": _vm.mode
    },
    on: {
      "changed": _vm.updateField
    }
  })], 1)], 1), _c('b-row', [_c('b-col', {
    attrs: {
      "lg": "6",
      "md": "6",
      "sm": "6"
    }
  }, [_c('inline-select', {
    attrs: {
      "id": _vm.controls.fermentation.id,
      "value": _vm.data.fermentation,
      "label": _vm.controls.fermentation.label,
      "readonly": _vm.controls.fermentation.readonly,
      "options": _vm.controls.fermentation.options,
      "allow-empty": false,
      "multiple": false,
      "mode": _vm.mode,
      "required": true
    },
    on: {
      "changed": _vm.updateSelect
    }
  })], 1), _c('b-col', {
    attrs: {
      "lg": "3",
      "md": "6",
      "sm": "6"
    }
  }, [_c('inline-select', {
    attrs: {
      "id": _vm.controls.distillation_container.id,
      "value": _vm.data.distillation_container,
      "label": _vm.controls.distillation_container.label,
      "readonly": _vm.controls.distillation_container.readonly,
      "options": _vm.controls.distillation_container.options,
      "allow-empty": false,
      "multiple": false,
      "mode": _vm.mode,
      "required": true
    },
    on: {
      "changed": _vm.updateSelect
    }
  })], 1), _c('b-col', {
    attrs: {
      "lg": "3",
      "md": "6",
      "sm": "6"
    }
  }, [_c('inline-select', {
    attrs: {
      "id": _vm.controls.distillation_status.id,
      "value": _vm.data.distillation_status,
      "label": _vm.controls.distillation_status.label,
      "readonly": _vm.controls.distillation_status.readonly,
      "options": _vm.controls.distillation_status.options,
      "allow-empty": false,
      "multiple": false,
      "mode": _vm.mode,
      "required": true
    },
    on: {
      "changed": _vm.updateSelect
    }
  })], 1)], 1), _c('b-row', [_c('b-col', {
    attrs: {
      "lg": "3",
      "md": "6",
      "sm": "6"
    }
  }, [_c('inline-input', {
    attrs: {
      "id": _vm.controls.amount_used.id,
      "value": _vm.data.amount_used,
      "label": _vm.controls.amount_used.label,
      "readonly": _vm.controls.amount_used.readonly,
      "mode": _vm.mode,
      "required": _vm.controls.amount_used.required
    },
    on: {
      "changed": _vm.updateField
    }
  })], 1), _c('b-col', {
    attrs: {
      "lg": "3",
      "md": "6",
      "sm": "6"
    }
  }, [_c('inline-date-picker', {
    attrs: {
      "id": _vm.controls.start_date.id,
      "value-single": _vm.data.start_date,
      "label": _vm.controls.start_date.label,
      "readonly": _vm.controls.start_date.readonly,
      "mode": _vm.mode,
      "required": _vm.controls.start_date.required
    },
    on: {
      "changed": _vm.updateDateField
    }
  })], 1), _c('b-col', {
    attrs: {
      "lg": "3",
      "md": "6",
      "sm": "6"
    }
  }, [_c('inline-time-picker', {
    attrs: {
      "id": _vm.controls.start_time.id,
      "value": _vm.data.start_time,
      "label": 'Start time',
      "readonly": _vm.controls.start_time.readonly,
      "required": _vm.controls.start_time.required,
      "mode": _vm.mode
    },
    on: {
      "changed": _vm.updateField
    }
  })], 1), _c('b-col', {
    attrs: {
      "lg": "3",
      "md": "6",
      "sm": "6"
    }
  }, [_c('inline-time-picker', {
    attrs: {
      "id": _vm.controls.end_time.id,
      "value": _vm.data.end_time,
      "label": 'End time',
      "readonly": _vm.controls.end_time.readonly,
      "required": _vm.controls.end_time.required,
      "mode": _vm.mode
    },
    on: {
      "changed": _vm.updateField
    }
  })], 1)], 1), _c('b-row', [_c('b-col', {
    attrs: {
      "lg": "3",
      "md": "6",
      "sm": "6"
    }
  }, [_c('inline-input', {
    attrs: {
      "id": _vm.controls.distillation_psi.id,
      "value": _vm.data.distillation_psi,
      "label": _vm.controls.distillation_psi.label,
      "readonly": _vm.controls.distillation_psi.readonly,
      "mode": _vm.mode,
      "required": _vm.controls.distillation_psi.required
    },
    on: {
      "changed": _vm.updateField
    }
  })], 1), _c('b-col', {
    attrs: {
      "lg": "3",
      "md": "6",
      "sm": "6"
    }
  }, [_c('inline-input', {
    attrs: {
      "id": _vm.controls.condensation_temp.id,
      "value": _vm.data.condensation_temp,
      "label": _vm.controls.condensation_temp.label,
      "readonly": _vm.controls.condensation_temp.readonly,
      "mode": _vm.mode,
      "required": _vm.controls.condensation_temp.required
    },
    on: {
      "changed": _vm.updateField
    }
  })], 1), _c('b-col', {
    attrs: {
      "lg": "3",
      "md": "6",
      "sm": "6"
    }
  }, [_c('inline-input', {
    attrs: {
      "id": _vm.controls.dephlegmator_1.id,
      "value": _vm.data.dephlegmator_1,
      "label": _vm.controls.dephlegmator_1.label,
      "readonly": _vm.controls.dephlegmator_1.readonly,
      "mode": _vm.mode,
      "required": _vm.controls.dephlegmator_1.required
    },
    on: {
      "changed": _vm.updateField
    }
  })], 1), _c('b-col', {
    attrs: {
      "lg": "3",
      "md": "6",
      "sm": "6"
    }
  }, [_c('inline-input', {
    attrs: {
      "id": _vm.controls.dephlegmator_2.id,
      "value": _vm.data.dephlegmator_2,
      "label": _vm.controls.dephlegmator_2.label,
      "readonly": _vm.controls.dephlegmator_2.readonly,
      "mode": _vm.mode,
      "required": _vm.controls.dephlegmator_2.required
    },
    on: {
      "changed": _vm.updateField
    }
  })], 1)], 1), _c('b-row', [_c('b-col', {
    attrs: {
      "lg": "3",
      "md": "6",
      "sm": "6"
    }
  }, [_c('inline-input', {
    attrs: {
      "id": _vm.controls.heads_amount.id,
      "value": _vm.data.heads_amount,
      "label": _vm.controls.heads_amount.label,
      "readonly": _vm.controls.heads_amount.readonly,
      "mode": _vm.mode,
      "required": _vm.controls.heads_amount.required
    },
    on: {
      "changed": _vm.updateField,
      "keyup": _vm.onKeyupField
    }
  })], 1), _c('b-col', {
    attrs: {
      "lg": "3",
      "md": "6",
      "sm": "6"
    }
  }, [_c('inline-input', {
    attrs: {
      "id": _vm.controls.hearts_amount.id,
      "value": _vm.data.hearts_amount,
      "label": _vm.controls.hearts_amount.label,
      "readonly": _vm.controls.hearts_amount.readonly,
      "mode": _vm.mode,
      "required": _vm.controls.hearts_amount.required
    },
    on: {
      "changed": _vm.updateField,
      "keyup": _vm.onKeyupField
    }
  })], 1), _c('b-col', {
    attrs: {
      "lg": "3",
      "md": "6",
      "sm": "6"
    }
  }, [_c('inline-input', {
    attrs: {
      "id": _vm.controls.hearts_amount_remaining.id,
      "value": _vm.data.hearts_amount_remaining,
      "label": _vm.controls.hearts_amount_remaining.label,
      "readonly": _vm.controls.hearts_amount_remaining.readonly,
      "mode": _vm.mode,
      "required": _vm.controls.hearts_amount_remaining.required
    },
    on: {
      "changed": _vm.updateField
    }
  })], 1), _c('b-col', {
    attrs: {
      "lg": "3",
      "md": "6",
      "sm": "6"
    }
  }, [_c('inline-input', {
    attrs: {
      "id": _vm.controls.tails_amount.id,
      "value": _vm.data.tails_amount,
      "label": _vm.controls.tails_amount.label,
      "readonly": _vm.controls.tails_amount.readonly,
      "mode": _vm.mode,
      "required": _vm.controls.tails_amount.required
    },
    on: {
      "changed": _vm.updateField,
      "keyup": _vm.onKeyupField
    }
  })], 1)], 1), _c('b-row', [_c('b-col', {
    attrs: {
      "lg": "3",
      "md": "6",
      "sm": "6"
    }
  }, [_c('inline-input', {
    attrs: {
      "id": _vm.controls.tails_amount_remaining.id,
      "value": _vm.data.tails_amount_remaining,
      "label": _vm.controls.tails_amount_remaining.label,
      "readonly": _vm.controls.tails_amount_remaining.readonly,
      "mode": _vm.mode,
      "required": _vm.controls.tails_amount_remaining.required
    },
    on: {
      "changed": _vm.updateField
    }
  })], 1), _c('b-col', {
    attrs: {
      "lg": "3",
      "md": "6",
      "sm": "6"
    }
  }, [_c('inline-input', {
    attrs: {
      "id": _vm.controls.distillation_inc.id,
      "value": _vm.data.distillation_inc,
      "label": _vm.controls.distillation_inc.label,
      "readonly": _vm.controls.distillation_inc.readonly,
      "mode": _vm.mode,
      "required": _vm.controls.distillation_inc.required
    },
    on: {
      "changed": _vm.updateField
    }
  })], 1), _c('b-col', {
    attrs: {
      "lg": "3",
      "md": "6",
      "sm": "6"
    }
  }, [_c('inline-input', {
    attrs: {
      "id": _vm.controls.distillation_flowrate.id,
      "value": _vm.data.distillation_flowrate,
      "label": _vm.controls.distillation_flowrate.label,
      "readonly": _vm.controls.distillation_flowrate.readonly,
      "mode": _vm.mode,
      "required": _vm.controls.distillation_flowrate.required
    },
    on: {
      "changed": _vm.updateField
    }
  })], 1), _c('b-col', {
    attrs: {
      "lg": "3",
      "md": "6",
      "sm": "6"
    }
  }, [_c('inline-input', {
    attrs: {
      "id": _vm.controls.heads_percentage.id,
      "value": _vm.data.heads_percentage,
      "label": _vm.controls.heads_percentage.label,
      "readonly": _vm.controls.heads_percentage.readonly,
      "mode": _vm.mode,
      "required": _vm.controls.heads_percentage.required
    },
    on: {
      "changed": _vm.updateField,
      "keyup": _vm.onKeyupField
    }
  })], 1)], 1), _c('b-row', [_c('b-col', {
    attrs: {
      "lg": "3",
      "md": "6",
      "sm": "6"
    }
  }, [_c('inline-input', {
    attrs: {
      "id": _vm.controls.hearts_percentage.id,
      "value": _vm.data.hearts_percentage,
      "label": _vm.controls.hearts_percentage.label,
      "readonly": _vm.controls.hearts_percentage.readonly,
      "mode": _vm.mode,
      "required": _vm.controls.hearts_percentage.required
    },
    on: {
      "changed": _vm.updateField,
      "keyup": _vm.onKeyupField
    }
  })], 1), _c('b-col', {
    attrs: {
      "lg": "3",
      "md": "6",
      "sm": "6"
    }
  }, [_c('inline-input', {
    attrs: {
      "id": _vm.controls.tails_percentage.id,
      "value": _vm.data.tails_percentage,
      "label": _vm.controls.tails_percentage.label,
      "readonly": _vm.controls.tails_percentage.readonly,
      "mode": _vm.mode,
      "required": _vm.controls.tails_percentage.required
    },
    on: {
      "changed": _vm.updateField,
      "keyup": _vm.onKeyupField
    }
  })], 1), _c('b-col', {
    attrs: {
      "lg": "3",
      "md": "6",
      "sm": "6"
    }
  }, [_c('inline-input', {
    attrs: {
      "id": _vm.controls.heads_pg.id,
      "value": _vm.data.heads_pg,
      "label": _vm.controls.heads_pg.label,
      "readonly": _vm.controls.heads_pg.readonly,
      "mode": _vm.mode,
      "required": _vm.controls.heads_pg.required
    },
    on: {
      "changed": _vm.updateField
    }
  })], 1), _c('b-col', {
    attrs: {
      "lg": "3",
      "md": "6",
      "sm": "6"
    }
  }, [_c('inline-input', {
    attrs: {
      "id": _vm.controls.hearts_pg.id,
      "value": _vm.data.hearts_pg,
      "label": _vm.controls.hearts_pg.label,
      "readonly": _vm.controls.hearts_pg.readonly,
      "mode": _vm.mode,
      "required": _vm.controls.hearts_pg.required
    },
    on: {
      "changed": _vm.updateField
    }
  })], 1)], 1), _c('b-row', [_c('b-col', {
    attrs: {
      "lg": "3",
      "md": "6",
      "sm": "6"
    }
  }, [_c('inline-input', {
    attrs: {
      "id": _vm.controls.tails_pg.id,
      "value": _vm.data.tails_pg,
      "label": _vm.controls.tails_pg.label,
      "readonly": _vm.controls.tails_pg.readonly,
      "mode": _vm.mode,
      "required": _vm.controls.tails_pg.required
    },
    on: {
      "changed": _vm.updateField
    }
  })], 1), _c('b-col', {
    attrs: {
      "lg": "3",
      "md": "6",
      "sm": "6"
    }
  }, [_c('inline-input', {
    attrs: {
      "id": _vm.controls.created_by.id,
      "value": _vm.data.created_by,
      "label": _vm.controls.created_by.label,
      "readonly": true
    }
  })], 1), _c('b-col', {
    attrs: {
      "lg": "3",
      "md": "6",
      "sm": "6"
    }
  }, [_c('inline-input', {
    attrs: {
      "id": _vm.controls.modified_by.id,
      "value": _vm.data.modified_by,
      "label": _vm.controls.modified_by.label,
      "readonly": true
    }
  })], 1), _c('b-col', {
    attrs: {
      "lg": "3",
      "md": "6",
      "sm": "6"
    }
  }, [_c('inline-input', {
    attrs: {
      "id": _vm.controls.created.id,
      "value": _vm.data.created,
      "label": _vm.controls.created.label,
      "readonly": true
    }
  })], 1)], 1), _c('b-row', [_c('b-col', {
    attrs: {
      "lg": "3",
      "md": "6",
      "sm": "6"
    }
  }, [_c('inline-input', {
    attrs: {
      "id": _vm.controls.modified.id,
      "value": _vm.data.modified,
      "label": _vm.controls.modified.label,
      "readonly": true
    }
  })], 1)], 1)], 1), _c('hr'), _c('form-submission-actions', {
    attrs: {
      "mode": _vm.mode,
      "loading": {
        save: _vm.saveInProgress,
        saveAndView: _vm.saveAndViewInProgress
      },
      "buttons-visibility": {
        previous: _vm.$customTable.getPrevKey(_vm.dataset, _vm.id),
        next: _vm.$customTable.getNextKey(_vm.dataset, _vm.id)
      }
    },
    on: {
      "previous-item": function previousItem($event) {
        _vm.$router.push({
          name: _vm.$route.name,
          params: {
            action: _vm.$route.params.action,
            id: _vm.$customTable.getPrevKey(_vm.dataset, _vm.id)
          }
        });
      },
      "next-item": function nextItem($event) {
        _vm.$router.push({
          name: _vm.$route.name,
          params: {
            action: _vm.$route.params.action,
            id: _vm.$customTable.getNextKey(_vm.dataset, _vm.id)
          }
        });
      },
      "save": function save($event) {
        _vm.save('tabular').then(function (response) {
          return response ? _vm.$router.push({
            name: 'Distillation'
          }) : false;
        });
      },
      "save-and-view": function saveAndView($event) {
        _vm.save('view').then(function (response) {
          return response ? _vm.$router.push({
            name: 'Distillation submission',
            params: {
              action: 'view',
              id: response
            }
          }) : false;
        });
      },
      "edit": function edit($event) {
        return _vm.$router.push({
          name: 'Distillation submission',
          params: {
            action: 'edit',
            id: _vm.id
          }
        });
      },
      "back": function back($event) {
        return _vm.$router.push(_vm.$store.getters['router/previousRoute']);
      }
    }
  })], 1), _vm.mode !== _vm.$constants.FORM_MODE.CREATE && _vm.data.fermentation.id ? _c('b-tab', {
    attrs: {
      "title": _vm.controls.tabs.mashings.title
    }
  }, [_c('mashing-table', {
    attrs: {
      "mash-id": _vm.data.fermentation.id,
      "show-add-button": false
    },
    on: {
      "loaded": _vm.onMashingsTableLoad
    }
  })], 1) : _vm._e(), _vm.mode !== _vm.$constants.FORM_MODE.CREATE && _vm.data.fermentation.id ? _c('b-tab', {
    attrs: {
      "title": _vm.controls.tabs.fermentations.title
    }
  }, [_c('fermentation-table', {
    attrs: {
      "mash-id": _vm.data.fermentation.id,
      "show-add-button": false
    },
    on: {
      "loaded": _vm.onFermentationsTableLoad
    }
  })], 1) : _vm._e(), _vm.mode !== _vm.$constants.FORM_MODE.CREATE ? _c('b-tab', {
    attrs: {
      "title": _vm.controls.tabs.storages.title,
      "active": _vm.moveHearts > 0 || _vm.moveTails > 0
    }
  }, [_c('distillation-storage-containers-table', {
    ref: "dist-storages-table",
    attrs: {
      "distillation-id": this.id
    },
    on: {
      "loaded": _vm.onDistillationStorageContainersLoad
    }
  })], 1) : _vm._e(), _vm.mode !== _vm.$constants.FORM_MODE.CREATE ? _c('b-tab', {
    attrs: {
      "title": _vm.controls.tabs.report.title
    },
    on: {
      "click": _vm.onDistillationReportTabClick
    }
  }, [_c('distillation-report', {
    ref: "distillation-report",
    attrs: {
      "distillation-id": this.id
    }
  })], 1) : _vm._e()], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }