<template>
  <div class="animated fadeIn">
    <b-card no-body>
      <b-container fluid class="pb-3">
        <g-chart-custom
          ref="chart1"
          title="Chart1"
          :subtitle="charts.chart1.subtitle"
          type="LineChart"
          :loading="charts.chart1.loading"
          :data="charts.chart1.chartData"
          :options="charts.chart1.chartOptions"
        >
        </g-chart-custom>

        <g-chart-custom
          ref="chart2"
          title="Alcohol"
          :subtitle="charts.chart2.subtitle"
          type="LineChart"
          :loading="charts.chart2.loading"
          :data="charts.chart2.chartData"
          :options="charts.chart2.chartOptions"
        >
        </g-chart-custom>

        <g-chart-custom
          ref="chart3"
          title="Chart3"
          :subtitle="charts.chart3.subtitle"
          type="LineChart"
          :loading="charts.chart3.loading"
          :data="charts.chart3.chartData"
          :options="charts.chart3.chartOptions"
        >
        </g-chart-custom>

        <g-chart-custom
          ref="chart4"
          title="AlcDens"
          :subtitle="charts.chart4.subtitle"
          type="LineChart"
          :loading="charts.chart4.loading"
          :data="charts.chart4.chartData"
          :options="charts.chart4.chartOptions"
        >
        </g-chart-custom>
        <g-chart-custom
          ref="chart5"
          title="StChPress"
          :subtitle="charts.chart5.subtitle"
          type="LineChart"
          :loading="charts.chart5.loading"
          :data="charts.chart5.chartData"
          :options="charts.chart5.chartOptions"
        >
        </g-chart-custom>
        <g-chart-custom
          ref="chart6"
          title="Chart6"
          :subtitle="charts.chart6.subtitle"
          type="LineChart"
          :loading="charts.chart6.loading"
          :data="charts.chart6.chartData"
          :options="charts.chart6.chartOptions"
        >
        </g-chart-custom>
      </b-container>
    </b-card>
  </div>
</template>

<script>
import GChartCustom from "@/components/GChartCustom";

export default {
  name: "DistillationReport",
  props: {
    distillationId: {
      type: [Number, String],
      default: ""
    }
  },
  components: {
    GChartCustom
  },
  data: function() {
    return {
      dataSet: [],
      test: 0,
      charts: {
        chart1: {
          loading: false,
          chartData: null,
          chartOptions: {
            width: "100%",
            height: 300,
            chartArea: {
              width: "80%",
              height: "80%"
            },

            title: "",
            subtitle: "",
            series: {
              0: {
                targetAxisIndex: 0
              }
            },
            vAxis: {
              title: ""
            },
            hAxis: {}
          }
        },
        chart2: {
          loading: false,
          chartData: null,
          chartOptions: {
            width: "100%",
            height: 300,
            chartArea: {
              width: "80%",
              height: "80%"
            },
            title: "",
            subtitle: "",
            series: {
              0: {
                targetAxisIndex: 0
              }
            },
            vAxis: {
              title: ""
            },
            hAxis: {}
          }
        },
        chart3: {
          loading: false,
          chartData: null,
          chartOptions: {
            width: "100%",
            height: 300,
            chartArea: {
              width: "80%",
              height: "80%"
            },
            title: "",
            subtitle: "",
            series: {
              0: {
                targetAxisIndex: 0
              }
            },
            vAxis: {
              title: ""
            },
            hAxis: {}
          }
        },
        chart4: {
          loading: false,
          chartData: null,
          chartOptions: {
            width: "100%",
            height: 300,
            chartArea: {
              width: "80%",
              height: "80%"
            },
            title: "",
            subtitle: "",
            series: {
              0: {
                targetAxisIndex: 0
              }
            },
            vAxis: {
              title: ""
            },
            hAxis: {}
          }
        },
        chart5: {
          loading: false,
          chartData: null,
          chartOptions: {
            width: "100%",
            height: 300,
            chartArea: {
              width: "80%",
              height: "80%"
            },
            title: "",
            subtitle: "",
            series: {
              0: {
                targetAxisIndex: 0
              }
            },
            vAxis: {
              title: ""
            },
            hAxis: {}
          }
        },
        chart6: {
          loading: false,
          chartData: null,
          chartOptions: {
            width: "100%",
            height: 300,
            chartArea: {
              width: "80%",
              height: "80%"
            },
            title: "",
            subtitle: "",
            series: {
              0: {
                targetAxisIndex: 0
              }
            },
            vAxis: {
              title: ""
            },
            hAxis: {}
          }
        }
      }
    };
  },
  computed: {},
  updated() {},
  async mounted() {},
  methods: {
    getData() {},

    async drawCharts() {
      if (!this.dataSet.length) {
        this.charts.chart1.loading = true;
        this.charts.chart2.loading = true;
        this.charts.chart3.loading = true;
        this.charts.chart4.loading = true;
        this.charts.chart5.loading = true;
        this.charts.chart6.loading = true;

        this.dataSet = await this.$api.get(
          `distillation/${this.distillationId}/log-auto-data`
        );

        this.charts.chart1.loading = false;
        this.charts.chart2.loading = false;
        this.charts.chart3.loading = false;
        this.charts.chart4.loading = false;
        this.charts.chart5.loading = false;
        this.charts.chart6.loading = false;
      }

      if (this.dataSet.length === 0) return;

      this.drawChart1();

      this.drawChart2();

      this.drawChart3();

      this.drawChart4();

      this.drawChart5();

      this.drawChart6();
    },
    async drawChart1() {
      let headers = [
        [
          {
            type: "datetime",
            id: "Time",
            label: "Time"
          },
          {
            type: "number",
            id: "Mash temperature",
            label: "Mash temperature"
          },
          {
            type: "number",
            id: "Column 1",
            label: "Column 1"
          },
          {
            type: "number",
            id: "Column 2",
            label: "Column 2"
          },
          {
            type: "number",
            id: "Linearm 1",
            label: "Linearm 1"
          }
        ]
      ];

      let data = this.dataSet.map(i => [
        new Date(Date.parse(i.date + " " + i.time)),
        i.MashT,
        i.col1top,
        i.col2top,
        i.LineT
      ]);

      this.charts.chart1.chartData = headers.concat(data);
    },
    async drawChart2() {
      let headers = [
        [
          {
            type: "datetime",
            id: "Time",
            label: "Time"
          },
          {
            type: "number",
            id: "AlcFlow",
            label: "AlcFlow"
          },
          {
            type: "number",
            id: "Hearts",
            label: "Hearts"
          },
          {
            type: "number",
            id: "Tails",
            label: "Tails"
          }
        ]
      ];

      let data = this.dataSet.map(i => [
        new Date(Date.parse(i.date + " " + i.time)),
        i.AlcFlow,
        i.hearts,
        i.tails
      ]);

      this.charts.chart2.chartData = headers.concat(data);
    },
    async drawChart3() {
      let headers = [
        [
          {
            type: "datetime",
            id: "Time",
            label: "Time"
          },
          {
            type: "number",
            id: "SteamValve %",
            label: "SteamValve %"
          },
          {
            type: "number",
            id: "Linearm 1-2 C",
            label: "Linearm 1-2 C"
          },
          {
            type: "number",
            id: "Col1 Top C",
            label: "Col1 Top C"
          },
          {
            type: "number",
            id: "Col2 Top C",
            label: "Col2 Top C"
          }
        ]
      ];

      let data = this.dataSet.map(i => [
        new Date(Date.parse(i.date + " " + i.time)),
        i.SteamValve,
        i.linearm1_2,
        i.col1top,
        i.col2top
      ]);

      this.charts.chart3.chartData = headers.concat(data);
    },
    async drawChart4() {
      let headers = [
        [
          {
            type: "datetime",
            id: "Time",
            label: "Time"
          },
          {
            type: "number",
            id: "AlcDens",
            label: "AlcDens"
          }
        ]
      ];

      let data = this.dataSet.map(i => [
        new Date(Date.parse(i.date + " " + i.time)),
        i.AlcDens
      ]);

      this.charts.chart4.chartData = headers.concat(data);
    },
    async drawChart5() {
      let headers = [
        [
          {
            type: "datetime",
            id: "Time",
            label: "Time"
          },
          {
            type: "number",
            id: "StChPress",
            label: "StChPress"
          }
        ]
      ];

      let data = this.dataSet.map(i => [
        new Date(Date.parse(i.date + " " + i.time)),
        i.StChPress
      ]);

      this.charts.chart5.chartData = headers.concat(data);
    },
    async drawChart6() {
      let headers = [
        [
          {
            type: "datetime",
            id: "Time",
            label: "Time"
          },
          {
            type: "number",
            id: "Col2top",
            label: "Col2top"
          },
          {
            type: "number",
            id: "Col2middle",
            label: "Col2middle"
          },
          {
            type: "number",
            id: "Col2bottom",
            label: "Col2bottom"
          }
        ]
      ];

      let data = this.dataSet.map(i => [
        new Date(Date.parse(i.date + " " + i.time)),
        i.col2top,
        i.col2middle,
        i.col2bottom
      ]);

      this.charts.chart6.chartData = headers.concat(data);
    }
  },
  watch: {}
};
</script>

<style></style>
