var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "animated fadeIn"
  }, [_c('b-card', {
    attrs: {
      "no-body": ""
    }
  }, [_c('b-container', {
    staticClass: "pb-3",
    attrs: {
      "fluid": ""
    }
  }, [_c('g-chart-custom', {
    ref: "chart1",
    attrs: {
      "title": "Chart1",
      "subtitle": _vm.charts.chart1.subtitle,
      "type": "LineChart",
      "loading": _vm.charts.chart1.loading,
      "data": _vm.charts.chart1.chartData,
      "options": _vm.charts.chart1.chartOptions
    }
  }), _c('g-chart-custom', {
    ref: "chart2",
    attrs: {
      "title": "Alcohol",
      "subtitle": _vm.charts.chart2.subtitle,
      "type": "LineChart",
      "loading": _vm.charts.chart2.loading,
      "data": _vm.charts.chart2.chartData,
      "options": _vm.charts.chart2.chartOptions
    }
  }), _c('g-chart-custom', {
    ref: "chart3",
    attrs: {
      "title": "Chart3",
      "subtitle": _vm.charts.chart3.subtitle,
      "type": "LineChart",
      "loading": _vm.charts.chart3.loading,
      "data": _vm.charts.chart3.chartData,
      "options": _vm.charts.chart3.chartOptions
    }
  }), _c('g-chart-custom', {
    ref: "chart4",
    attrs: {
      "title": "AlcDens",
      "subtitle": _vm.charts.chart4.subtitle,
      "type": "LineChart",
      "loading": _vm.charts.chart4.loading,
      "data": _vm.charts.chart4.chartData,
      "options": _vm.charts.chart4.chartOptions
    }
  }), _c('g-chart-custom', {
    ref: "chart5",
    attrs: {
      "title": "StChPress",
      "subtitle": _vm.charts.chart5.subtitle,
      "type": "LineChart",
      "loading": _vm.charts.chart5.loading,
      "data": _vm.charts.chart5.chartData,
      "options": _vm.charts.chart5.chartOptions
    }
  }), _c('g-chart-custom', {
    ref: "chart6",
    attrs: {
      "title": "Chart6",
      "subtitle": _vm.charts.chart6.subtitle,
      "type": "LineChart",
      "loading": _vm.charts.chart6.loading,
      "data": _vm.charts.chart6.chartData,
      "options": _vm.charts.chart6.chartOptions
    }
  })], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }