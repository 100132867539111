<template>
  <div class="animated fadeIn">
    <b-row>
      <b-col>
        <b-button-group>
          <b-button
            variant="outline-dark"
            title="Add distillation"
            @click="addItem()"
          >
            <font-awesome-icon icon="plus" /> Add distillation
          </b-button>
        </b-button-group>
      </b-col>
    </b-row>

    <table-custom
      ref="dataTable"
      :name="`${$customTable.getCustomTableName(dataset)}`"
      :loading="dataTable.isLoading"
      :data="dataTable.dataSet"
      :options="dataTable.options"
      @inline-update="onInlineUpdate"
      @inline-dropdown-change="onInlineDropdownChange"
      @column-update="onInlineColumnUpdate"
    >
      <div slot="custom-actions" slot-scope="props">
        <div class="btn-group">
          <button
            class="btn btn-danger btn-sm"
            @click="deleteItem(props.row)"
            title="Delete item"
          >
            <font-awesome-icon icon="trash" />
          </button>
        </div>
      </div>
    </table-custom>
  </div>
</template>

<script>
import Vue from "vue";
import TableCustom from "@/components/TableCustom";

export default {
  props: {
    distillationId: {
      type: [String, Number],
      default: null
    }
  },
  name: "StorageContainerItemsTable",
  components: {
    TableCustom
  },
  data: function() {
    return {
      dataset: {
        name: "distillation-storages"
      },
      mode: null,
      rawData: {},
      dataTable: {
        view: 1,
        isLoading: false,
        dataSet: [],
        options: {
          uniqueKey: "ID",
          showChildRowToggler: false,
          showEmpty: true,
          filterable: [],
          columns: [
            "ID",
            "Container Number",
            "Distillate",
            "Amount",
            "Amount Remaining",
            "Created By",
            "Created",
            "Actions"
          ],

          editableColumns: ["Container Number", "Amount", "Distillate"],
          maskedColumns: [],
          dropdownColumns: [
            { name: "Container Number", options: [] },
            {
              name: "Distillate",
              options: [
                { id: "Hearts", label: "Hearts" },
                { id: "Tails", label: "Tails" }
              ]
            }
          ],
          perPage: 50,
          showCustomActions: true,
          //showActions: true,
          showChildRows: true,
          selectableRows: this.selectableRows,
          saveNewRecordCallback: this.saveNewRecordCallback,
          revertNewRecordCallback: this.revertNewRecordCallback
        },
        childRow: {
          showDescription: false
        }
      }
    };
  },
  computed: {},
  mounted() {
    this.initialize();
  },
  methods: {
    async initialize() {
      this.mode = this.$constants.FORM_MODE.VIEW;

      this.loadDictionaries();

      if (!this.$form.isCreateMode(this) && this.distillationId) {
        this.getData();
      }
    },
    async getData() {
      let self = this;

      this.dataTable.isLoading = true;

      let response = [];

      try {
        response = await this.$api.get(
          `distillation/${this.distillationId}/storages`
        );

        self.dataTable.isLoading = false;

        self.dataTable.dataSet = response;

        self.$emit("loaded", self.dataTable.dataSet.length);
        if (response.length === 0) return;
      } catch (error) {
        console.log(error);
        self.dataTable.isLoading = false;
        self.$form.msgBoxOk("Error occured");
      }
    },

    onInlineDropdownChange(e) {
      let rowIndex = this.dataTable.dataSet.findIndex(
        i => i["ID"] === e.row["ID"]
      );
      let row = this.dataTable.dataSet[rowIndex];

      if (e.column === "Container Number") {
        row["container_id"] = e.value.id;
      }

      if (e.column === "Distillate") {
        row["Distillate"] = e.value.id;
      }

      Vue.set(this.dataTable.dataSet, rowIndex, row);
    },
    onInlineColumnUpdate() {},
    onInlineUpdate(e) {
      console.log("onInlineUpdate", JSON.stringify(e));

      let row = this.dataTable.dataSet.find(i => i["ID"] === e.id);

      if (e.column === "Container Number") row["container_id"] = e.value.id;

      if (e.column === "Distillate") row["distillate"] = e.value.id;

      if (e.column === "Amount") row["amount"] = e.value;

      if (this.$form.isViewMode(this)) {
        this.$api
          .put(`storage-container/distillations/${row.ID}`, row)
          .then(response => {
            this.$form.makeToastInfo(response.message);

            this.getData();
          })
          .catch(function(error) {
            this.$form.makeToastError(error.message);
            this.getData();
          });
      }
    },
    loadDictionaries() {
      let self = this;

      const items = () => {
        self.$api.get("dictionaries/storage-containers").then(response => {
          self.dataTable.options.dropdownColumns.find(
            c => c.name === "Container Number"
          ).options = response.map(u => ({
            id: u.id,
            label: u.name
          }));
        });
      };

      Promise.all([items()]);
    },

    getDataSet() {
      return this.dataTable.dataSet;
    },
    addItem(payload) {
      console.log("addItem", payload);
      let newItem = {
        uid: this.$helpers.uuidv4(),
        ID: this.$constants.CUSTOM_TABLE.NEW_ROW_ID,
        Amount: "",
        "Amount Remaining": "",
        Distillate: "",
        "Container Number": "",
        "Container Capacity": "",
        "Created By": "",
        Created: ""
      };

      if (payload) {
        newItem.Distillate = payload.Distillate;
        newItem.Amount = payload.Amount;
      }

      this.dataTable.dataSet = this.$refs.dataTable.insertNewRow(newItem);

      this.dataTable.isInserting = true;
    },

    async saveNewRecordCallback(newRecord) {
      let self = this;
      console.log(JSON.stringify(newRecord));
      if (!newRecord["Container Number"]) {
        self.$form.makeToastError("Please select container");
        return;
      }
      if (!newRecord["Amount"]) {
        self.$form.makeToastError("Please select amount");
        return;
      }

      let data = {
        id: this.$constants.CUSTOM_TABLE.NEW_ROW_ID
      };

      data["distillation_id"] = this.distillationId;
      data["container_id"] = newRecord["Container Number_ID"];
      data["distillate"] = newRecord["Distillate"];
      data["amount"] = newRecord["Amount"];

      self.dataTable.isInserting = false;

      if (this.$form.isViewMode(this) && this.distillationId) {
        this.$api
          .put(`storage-container/${data["container_id"]}/distillations`, data)
          .then(response => {
            self.$form.makeToastInfo(response.message);

            self.getData();
          })
          .catch(function(error) {
            self.$form.makeToastError(error.message);

            self.getData();
          });
      }

      return true;
    },

    async revertNewRecordCallback() {
      this.dataTable.isInserting = false;

      return true;
    },
    async deleteItem(row) {
      //let item = this.dataTable.dataSet.find(item => item.ID === row.ID);

      let confirm = await this.$form.showConfirmation(
        `Distillation will be removed. Do you want to proceed?`
      );

      if (!confirm) return;

      let idProp =
        this.mode === this.$constants.FORM_MODE.CREATE ? "uid" : "ID";

      this.dataTable.dataSet = this.dataTable.dataSet.filter(
        i => i[idProp] !== row[idProp]
      );
      let self = this;

      if (this.mode === this.$constants.FORM_MODE.VIEW) {
        self.$api
          .delete(`storage-container/distillations/${row.ID}`)
          .then(() => {
            self.$form.makeToastInfo("Distillation deleted");
          })
          .catch(response => {
            console.log(response);

            self.$form.makeToastError(response.data.message);

            self.getData();
          });
      }
    }
  },
  watch: {}
};
</script>

<style scoped>
/*
::v-deep .VueTables__table {
  overflow: visible !important;
}
::v-deep .table-responsive {
  overflow: visible !important;
}*/
</style>
